<template>
  <section id="dashboard">
    <v-row>
      <v-col cols="12" lg="3">
        <v-card rounded="lg" elevation="0">
          <v-card-text class="d-flex align-center">
            <div class="">
              <v-icon size="45" color="success"> mdi-book-outline </v-icon>
            </div>
            <div class="ml-5">
              <div class="caption text-center">TOTAL COURSES</div>
              <div class="display-1">
                {{ courses.total }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="8">
        <div class="d-flex justify-end">
          <div style="width: 110px">
            <count store="student" action="coursesGetAction" />
          </div>
        </div>
        <Table :data="courses.data" :user="user" />
        <paginate
          v-if="courses.total > 0"
          store="student"
          collection="courses"
          class="mx-auto"
        />
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import Table from "../components/dashboard/Table.vue";

export default {
  components: {
    Table,
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...mapState("student", {
      courses: (state) => state.courses,
      user: (state) => state.user,
    }),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions("student", ["coursesGetAction"]),
    ...mapMutations("student", ["resetPageMutation"]),

    fetch() {
      this.coursesGetAction({
        page: 0,
        query: "",
        date: "",
        count: 10,
      }).then(() => {
        this.loading = false;
        this.resetPageMutation();
      });
    },
  },
};
</script>